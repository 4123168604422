import EzComponent from '@frontend/components/EzComponent';
import { connect } from 'react-redux'


export class DesktopParser extends EzComponent {
    render() {
        const { children, isMobile } = this.props;
        const mobile = isMobile.isMobile
        return (
            <>
                {
                    !mobile &&
                    children
                }
            </>
        );
    }
}

function mapStateToProps(isMobile) {
    return {
        isMobile
    }
}
export default connect(mapStateToProps, {})(DesktopParser);
